<template>
  <div class="video">
    <ContentHeader
      :title="header.title"
      :color="header.color"
      :logo="header.icon"
      :show-back-button="true"
      :show-logo-when-small="false"
      :back-button-name="backButtonName"
    />
    <TVWrapper>
      <div class="video__content">
        <VideoPlayer
          :enable-skeleton="enableSkeleton"
          :embed-html="video.embedHtml"
        />
      </div>
      <skeleton-bone
        v-if="loading || enableSkeleton"
        class="video__skeleton-title"
      />
      <div
        v-else
        class="video__title"
      >
        <h2>{{ video.title }}</h2>
      </div>
      <Download
        v-if="video.files && video.files.length"
        :files="video.files"
      />
    </TVWrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions,mapState } from 'vuex'
import ContentHeader from '@/components/ContentHeader'
import VideoPlayer from '@/components/VideoPlayer'
import Download from '@/components/global/Download'
import loadHeader from '@/mixins/loadHeader'
import trackPlayerEvent from '@/mixins/trackPlayerEvent'

export default {
  name: 'Video',
  components: {
    ContentHeader,
    VideoPlayer,
    Download,
  },
  mixins: [
    loadHeader,
    trackPlayerEvent,
  ],
  data() {
    return {
      enableSkeleton: true,
    }
  },
  computed: {
    ...mapGetters([
      'video',
      'loading',
      'selectedCategories',
    ]),
    ...mapState('profile', ['personalData']),
    backButtonName() {
      return 'Todos os vídeos'
    }
  },
  watch: {
    video: {
      immediate: true,
      handler() {
        const hasTrackEventData = Boolean(this.video.chapterNumber && this.personalData.id)

        if (hasTrackEventData) {
          const { chapterNumber: chapter } = this.video

          this.setupPlayerEvents({ user: this.personalData, chapter })
        }
      },
    },
  },
  destroyed() {
    this.destroyedGetVideoById()
  },
  mounted() {
    this.getVideo()
  },
  methods: {
    ...mapActions([
      'getVideoById',
      'destroyedGetVideoById',
      'clearSelectedCategories',
    ]),
    getVideo() {
      if (this.$route.query.qrcode) {
        localStorage.removeItem('selectedCategory')
        this.clearSelectedCategories()
        this.header = {
          title: undefined,
          color: undefined,
          icon: null,
        }
      }

      this.setEnableSkeleton()
      this.getVideoById(this.$route.params.video)
    },
    setEnableSkeleton() {
      this.enableSkeleton = false
    }
  },

}
</script>

<style lang="sass">
.video
  &__title
    padding: 25px

    h2
      font-size: 20px

  &__skeleton-title
    width: 100%
    height: 62px !important
    margin-top: 25px

.wrapper
  +mq-s
    max-width: 100%
</style>
