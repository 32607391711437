<template>
  <TVWrapper>
    <div class="video-skeleton__body">
      <skeleton-bone class="video-skeleton__skeleton" />
    </div>
  </TVWrapper>
</template>

<script>
export default {
    name: 'VideoSkeleton'
}
</script>

<style lang="sass">

.video-skeleton
  &__body
    .skeleton-bone
      display: block
      border-radius: 0px !important

    +mq-l
      position: relative
      padding-bottom: 56.25%

  &__skeleton
    width: 100%
    height: 600px !important

    +mq-xl
      height: 510px !important
      width: 100%

    +mq-l
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100% !important

</style>
