import VimeoPlayer from '@vimeo/player'

export default {
  methods: {
    setupPlayerEvents({ user, ...additional }) {
      const additionalParams = additional ? { ...additional } : {}

      setTimeout(() => {
        const iframe = document.querySelector('iframe')
        const player = new VimeoPlayer(iframe)

        player.on('play', () => {
          window.dataLayer.push({
            event: 'custom_event_analytics',
            category: 'SASTV',
            action: 'AssistirVideoCapítulo',
            accountId: user.id,
            ...additionalParams,
          })
        })
      }, 100)
    },
  },
}
