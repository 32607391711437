<template>
  <div id="v-step-6">
    <VideoSkeleton v-if="loading || enableSkeleton" />
    <div
      v-else
      class="video__player"
      v-html="embedHtml"
    />
    <TourVideoPlayer
      v-if="isTourActive && !loading"
      @skip="skipTour"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VideoSkeleton from '@/components/VideoSkeleton'
import tour from '@/mixins/tour'
const TourVideoPlayer = () => import('@/components/TourVideoPlayer')

export default {
  name: 'VideoPlayer',
  components: {
    VideoSkeleton,
    TourVideoPlayer,
  },
  mixins: [
    tour,
  ],
  props: {
    enableSkeleton: {
      type: Boolean,
      required: false,
      default: false,
    },
    embedHtml: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'loading',
    ]),
  },
}
</script>

<style lang="sass">

.video__player
  iframe
    width: 1030px
    height: 577px

    +mq-xl
      max-width: 992px
      height: 567px

    +mq-l
      max-width: 720px
      height: 414px

    +mq-m
      width: 100%
      max-height: 414px

    +mq-s
      width: 100%
      max-height: 220px

    +mq-xs
      width: 100%
      max-height: 180px
</style>
