<template>
  <div>
    <div
      v-for="(item, index) in items"
      :key="index"
      class="download"
    >
      <div
        v-if="Object.entries(item).length"
        class="download-wrapper"
      >
        <h4>{{ item.title }}</h4>
        <TVButton
          :loading="loading && (itemIndex === index)"
          class="download-wrapper__button"
          @click="downloadFile(item.link, index)"
        >
          <Icon type="download" />
          <h5 class="download-wrapper__button__text">
            {{ item.buttonText }}
          </h5>
        </TVButton>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { saveAs } from 'file-saver'

export default {
  name: 'Download',
  props: {
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      itemIndex: null,
    }
  },
  mounted() {
    this.prepareDownload()
  },
  destroyed() {
    this.items = []
    this.itemIndex = null
  },
  methods: {
    prepareDownload() {
      let fileItem = {}
      this.files.map(file => {
        switch (file.type) {
          case 'audio':
            fileItem = {
              title: 'Baixe o áudio em MP3',
              buttonText: 'Baixe o áudio em MP3',
              link: file.url,
            }
            break

          case 'writing_practice':
            fileItem = {
              title: 'Vamos praticar?',
              buttonText: 'Baixar Propostas',
              link: file.url,
            }
            break

          default:
            fileItem = {}
            break
        }

        return this.items.push(fileItem)
      })
    },
    async downloadFile(link, index) {
      this.itemIndex = index
      this.loading = true
      try {
        const response = await axios({
          method: 'get',
          url: link,
          responseType: 'blob',
        })
        const fileName = response.headers['content-disposition'] || this.extractFileName(link)

        if (this.isIos()) {
          this.createdBlob(
            link,
            response.headers['content-type'],
            fileName.split('=').pop(),
          )
        } else {
          this.createdBlob(
            response.data,
            response.headers['content-type'],
            fileName.split('=').pop(),
          )
        }
      } catch (error) {
        console.error(error)
        Vue.toasted.global.error({
          message: 'Não foi possível realizar o download, por favor tente mais tarde',
          errorCode: error.response ? error.response.status : null
        })
      } finally {
        this.loading = false
      }
    },
    createdBlob(data, contentType, fileName) {
      const blob = new Blob([data], { type: contentType })

      if (this.isIos()) {
        const reader = new FileReader()
        reader.onload = () => {
          window.location.href = data
        }
        reader.filename = fileName
        reader.readAsDataURL(blob)
      } else {
        saveAs(blob, fileName)
      }
    },
    isIos() {
      return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
   },
   extractFileName(link){
     if(link){
       const linkSplited = link.split('/');
       const nameWithExtension = linkSplited[linkSplited.length - 1];
       const nameWithExtensionSplited = nameWithExtension.split('.');
       return nameWithExtensionSplited[0];
     }

     return 'temp'
   }
  },
}
</script>

<style lang="sass" scoped>
.download
  .download-wrapper
    display: flex
    align-items: center
    justify-content: space-between
    color: white
    background-color: $color-main
    padding: 21px 64px
    margin-bottom: 1px

    +mq-s
      flex-flow: column
      padding: 21px

      h4
        margin-bottom: 20px

    &__button
      background-color: $color-secondary
      height: 48px

      &__text
        padding-left: 10px
</style>
